//imports
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Poppins:wght@300;400&display=swap");

/*---VARIABLES--*/
$main: "Major Mono Display", monospace;

@mixin flex-column {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@mixin flex-row {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

@mixin backgroundImage {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

/*---BODY---*/
* {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*---HOME/ABOUT PAGE--*/
.header {
	background-image: url("https://i.imgur.com/W6FpaGa.jpg");
	background-image: linear-gradient(
			to top right,
			rgba(50, 22, 55, 0.842),
			rgba(122, 46, 105, 0.582),
			rgba(160, 63, 89),
			rgba(237, 166, 108, 0.918)
		),
		url("https://i.imgur.com/W6FpaGa.jpg");
	background-image: -webkit-linear-gradient(
			to top right,
			rgba(50, 22, 55, 0.842),
			rgba(122, 46, 105, 0.582),
			rgba(160, 63, 89),
			rgba(237, 166, 108, 0.918)
		),
		url("https://i.imgur.com/W6FpaGa.jpg");
	background-image: -moz-linear-gradient(
			to top right,
			rgba(50, 22, 55, 0.842),
			rgba(122, 46, 105, 0.582),
			rgba(160, 63, 89),
			rgba(237, 166, 108, 0.918)
		),
		url("https://i.imgur.com/W6FpaGa.jpg");
	@include backgroundImage;
	height: 50vh;
	width: 100%;
	@include flex-column;
	text-align: center;
	color: #fff;
}

.home-title {
	font-family: $main;
	font-weight: bold;
	color: #fff;
	font-size: 28px;
}

.home-sub {
	margin-top: 10px;
	color: #fff;
	font-size: 21px;
}

/*---about section---*/
.about-container {
	@include flex-column;
	text-align: center;
	padding-bottom: 10px;
}

.about-img {
	width: 75%;
	position: relative;
	top: -70px;
	border: 5px solid #fff;
}

.about-text-container {
	@include flex-column;
	text-align: center;
}

.about-title,
.skills-title {
	font-family: $main;
	font-weight: bold;
	text-decoration: underline;
	font-size: 25px;
	margin-bottom: 30px;
	margin-top: -30px;
}

.about-text {
	width: 80%;
}

/*--highlights--*/
.highlight-section-container {
	padding-bottom: 5px;
}

.highlight-container {
	@include flex-column;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 50px;
}

.highlight-icon {
	font-size: 55px;
	margin-bottom: 0;
}

.highlight-par {
	font-size: 15px;
	width: 65%;
	margin-top: -10px;
}

/*--skills section--*/
.skills-container {
	background: linear-gradient(
		to left,
		rgba(241, 94, 64, 0.774),
		rgba(235, 120, 66, 0.822),
		rgba(238, 175, 87, 0.979)
	);
	background-image: linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	background-image: -webkit-linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	background-image: -moz-linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	@include flex-column;
	text-align: center;
	color: #fff;
}

.skills-title {
	margin-top: 40px;
	margin-bottom: 60px;
}

/*--bubbles--*/
.tech-comp-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

.tech-container {
	@include flex-column;
	text-align: center;
	background-color: rgba(50, 22, 55, 0.801);
	width: 110px;
	height: 110px;
	border-radius: 50%;
	margin: 10px;
	padding: 10px;
}

.tech-icon {
	font-size: 32px;
}

.tech-label {
	font-size: 13px;
	margin-top: -8px;
	padding: 3px;
}

/*-----NAV-----*/
.navbar {
	position: absolute;
	top: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: transparent;
	width: 100vw;
	padding: 5px;
}

.navbar span,
.nav-link {
	color: #fff;
	cursor: pointer;
	font-size: 14px;
}

.navbar span:hover,
.nav-link:hover {
	color: rgba(238, 175, 87, 0.979);
	text-decoration: underline;
}

.navbar span:visited,
.nav-link:visited {
	text-decoration: none;
}

/*----PROJECTS PAGE----*/
.project-main-container {
	background: linear-gradient(
		to left,
		rgba(241, 94, 64, 0.774),
		rgba(235, 120, 66, 0.822),
		rgba(238, 175, 87, 0.979)
	);
	background-image: linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	background-image: -webkit-linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	background-image: -moz-linear-gradient(
			to left,
			rgba(241, 94, 64, 0.774),
			rgba(235, 120, 66, 0.822),
			rgba(238, 175, 87, 0.979)
		),
		url("https://i.imgur.com/drYUl8X.jpg");
	@include flex-column;
}

.proj-comp-container {
	margin-top: 10px;
	width: 100vw;
	@include flex-column;
}

.proj-title {
	font-family: $main;
	font-weight: bold;
	color: #fff;
	font-size: 30px;
	text-align: left;
	width: 90vw;
	padding: 90px 0px 40px 0px;
}

.block,
.proj-blurb {
	padding: 10px;
	margin-top: 10px;
	@include flex-column;
	text-align: center;
	width: 85vw;
	background-color: rgba(255, 255, 255, 0.515);
	margin-bottom: 60px;
}

.card-content {
	width: 99%;
	padding: 10px;
	@include flex-column;
}

.proj-blurb {
	span {
		padding: 3px;
	}

	p {
		font-size: 13px;
	}
}

.proj-nav {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

.giphy {
	width: 95%;
}

#id3 {
	max-height: 270px;
	width: 70%;
}

.content {
	width: 99%;

	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 20px;
	}

	p {
		font-size: 13px;
	}
}

.link-icon,
.proj-link {
	margin-top: 20px;
	font-size: 20px;
	color: rgba(50, 22, 55, 0.801);
}

/*----FOOTER----*/
.footer {
	background-color: #000;
	border: 5px solid #000;
	padding: 3px;
}

.footer-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-icon {
	font-size: 20px;
	color: #fff;
	margin: 30px;
}

/*----CONTACT PAGE----*/
.contact-container {
	background-image: url("https://i.imgur.com/jQGIJLW.jpg");
	background-image: linear-gradient(rgba(2, 2, 2, 0.979), rgba(4, 4, 4, 0.521)),
		url("https://i.imgur.com/jQGIJLW.jpg");
	background-image: -webkit-linear-gradient(
			rgba(2, 2, 2, 0.979),
			rgba(4, 4, 4, 0.521)
		),
		url("https://i.imgur.com/jQGIJLW.jpg");
	background-image: -moz-linear-gradient(
			rgba(2, 2, 2, 0.979),
			rgba(4, 4, 4, 0.521)
		),
		url("https://i.imgur.com/jQGIJLW.jpg");
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include backgroundImage;
	height: 100%;
}

.contact-title {
	font-family: "Major Mono Display", monospace;
	color: #fff;
	font-size: 30px;
	margin-top: 60%;
	margin-bottom: 20%;
}

.photo-container {
	background-color: rgba(255, 255, 255, 0.692);
	width: 85%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-content: center;
	margin-bottom: 10%;
}

.contact-blurb {
	font-size: 15px;
	text-align: center;
	margin: 10px auto 12px auto;
}

.photo-box {
	margin-bottom: 10px;
	width: 100%;

	p {
		font-size: 11px;
	}
}

.main-link-container {
	background-color: rgba(255, 255, 255, 0.692);
	width: 85%;
	padding: 15px;
	margin-bottom: 20%;
}

.contact-link-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 10px;

	a {
		display: flex;
		align-items: center;
		margin: 8px;
		color: rgba(65, 65, 65);
	}

	span:hover {
		text-decoration: underline;
	}

	span {
		margin-left: 10px;
		font-size: 14px;
	}
}

.contact-icon {
	font-size: 17px;
}

/*-----MQ-----*/
@media screen and (min-width: 540px) {
	/*---home page--*/
	.home-title {
		font-size: 43px;
	}

	.home-sub {
		margin-top: 20px;
		font-size: 30px;
	}

	/*---about section---*/
	.about-img {
		height: 55%;
		width: 60%;
		border: 6px solid #fff;
	}

	.about-title,
	.skills-title {
		font-size: 40px;
		margin-bottom: 30px;
	}

	.about-text {
		font-size: 19px;
		margin-bottom: 20px;
	}

	/*--highlights--*/
	.highlight-section-container {
		padding-bottom: 15px;
		@include flex-row;
		width: 100%;
	}

	.highlight-container {
		width: 35%;
	}

	.highlight-icon {
		font-size: 65px;
	}

	.highlight-par {
		font-size: 15px;
		width: 100%;
		margin-top: -10px;
	}

	/*--skills section--*/
	.skills-title {
		margin-bottom: 50px;
	}

	/*--bubbles--*/
	.tech-container {
		width: 135px;
		height: 135px;
		margin: 35px;
	}

	.tech-icon {
		font-size: 45px;
	}

	.tech-label {
		font-size: 16px;
	}

	/*--nav--*/
	.navbar span,
	.nav-link {
		font-size: 20px;
	}

	/*--projects--*/
	.block,
	.proj-blurb {
		padding: 10px;
		width: 75%;
	}

	.card-content {
		width: 95%;
	}

	.giphy {
		width: 90%;
	}

	#id3 {
		width: 50%;
		height: auto;
	}

	.content {
		margin-top: 20px;
		width: 90%;
	}

	.proj-nav {
		width: 60%;
		margin-top: 10px;
	}

	.link-icon,
	.proj-link {
		font-size: 21px;
	}

	/*--footer--*/
	.footer-icon {
		font-size: 25px;
		margin: 40px;
	}

	/*----contact page----*/
	.contact-title {
		font-size: 40px;
	}

	.photo-container {
		width: 80%;
		padding: 20px;

		h1 {
			font-size: 18px;
		}
	}

	.contact-blurb {
		font-size: 22px;
		margin: 10px auto 20px auto;
	}

	.photo-box {
		p {
			font-size: 15px;
		}
	}

	.contact-img {
		width: 90%;
	}

	.main-link-container {
		width: 80%;
	}

	.contact-link-container span {
		margin-left: 15px;
		font-size: 17px;
	}

	.contact-icon {
		font-size: 18px;
	}
}

@media screen and (min-width: 768px) {
	/*---home page--*/
	.home-title {
		font-size: 50px;
	}

	.home-sub {
		margin-top: 20px;
		font-size: 30px;
	}

	/*---about section---*/
	.about-container {
		padding-bottom: 50px;
	}

	.about-img {
		height: 50%;
		width: 50%;
		top: -80px;
		border: 7px solid #fff;
	}

	.about-title,
	.skills-title {
		font-size: 40px;
		margin-bottom: 40px;
	}

	.about-text {
		font-size: 20px;
		margin-bottom: 35px;
	}

	/*--highlights--*/
	.highlight-section-container {
		padding-bottom: 15px;
		@include flex-row;
	}

	.highlight-container {
		width: 35%;
	}

	.highlight-icon {
		font-size: 65px;
	}

	.highlight-par {
		font-size: 19px;
		width: 85%;
		margin-top: -10px;
	}

	/*--skills section--*/
	.skills-title {
		margin-top: 40px;
		margin-bottom: 50px;
		font-size: 40px;
	}

	/*--bubbles--*/
	.tech-container {
		width: 150px;
		height: 150px;
	}

	.tech-icon {
		font-size: 45px;
	}

	.tech-label {
		font-size: 18px;
	}

	/*--nav--*/
	.navbar span,
	.nav-link {
		font-size: 25px;
	}

	/*--projects page--*/
	.project-comp-container {
		justify-content: space-around;
	}

	.proj-title {
		margin-top: 70px;
		font-size: 40px;
	}

	.block,
	.proj-blurb {
		padding: 15px 5px;
		margin-top: 20px;
		width: 75%;
		background-color: rgba(255, 255, 255, 0.515);
		margin-bottom: 60px;
	}

	.card-content {
		@include flex-row;
		flex-wrap: wrap;
	}

	.proj-nav {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 60%;
	}

	.giphy {
		width: 45%;
		margin-right: 8%;
		height: 17vh;
	}

	#id3 {
		width: 36%;
		height: 25vh;
	}

	.content {
		width: 40%;
		@include flex-column;

		h2 {
			font-size: 25px;
		}

		p {
			font-size: 15px;
		}
	}

	.proj-blurb {
		padding: 20px;

		span {
			padding: 3px;
		}

		p {
			font-size: 16px;
		}
	}

	.link-icon,
	.proj-link {
		margin-top: 10px;
		font-size: 28px;
		color: rgba(50, 22, 55, 0.801);
	}

	/*--footer--*/
	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 5px solid black;
	}

	.footer-icon-container {
		width: 100vw;
	}

	/*----contact page----*/
	.contact-title {
		font-size: 45px;
		margin-bottom: 30%;
	}

	.photo-container {
		width: 70%;

		h1 {
			font-size: 25px;
		}

		p {
			font-size: 17px;
		}
	}

	.main-link-container {
		width: 70%;
	}

	.contact-link-container span {
		margin-left: 15px;
		font-size: 20px;
	}

	.contact-icon {
		font-size: 23px;
	}
}

@media screen and (min-width: 950px) {
	/*---home page--*/
	.home-title {
		font-size: 60px;
	}

	.home-sub {
		margin-top: 28px;
		font-size: 39px;
	}

	/*---about section---*/
	.about-container {
		padding-bottom: 55px;
	}

	.about-title,
	.skills-title {
		margin-top: -10px;
		font-size: 45px;
		margin-bottom: 50px;
	}

	.about-text {
		font-size: 24px;
		margin-bottom: 40px;
	}

	/*--highlights--*/
	.highlight-section-container {
		padding-bottom: 20px;
	}

	.highlight-container {
		width: 35%;
	}

	.highlight-icon {
		font-size: 80px;
	}

	.highlight-par {
		font-size: 22px;
	}

	/*--skills section--*/
	.skills-title {
		margin-top: 50px;
		margin-bottom: 80px;
		font-size: 45px;
	}

	/*--bubbles--*/
	.tech-container {
		width: 180px;
		height: 180px;
	}

	.tech-icon {
		font-size: 50px;
	}

	.tech-label {
		font-size: 20px;
	}

	/*--nav--*/
	.navbar span,
	.nav-link {
		font-size: 27px;
	}

	/*--projects page--*/
	.proj-comp-container {
		margin-top: 80px;
	}

	.proj-title {
		font-size: 60px;
		padding-top: 30px;
		margin-left: 20px;
	}

	.block,
	.proj-blurb {
		padding: 25px 20px;
		margin-bottom: 80px;
	}

	.content {
		width: 45%;

		h2 {
			font-size: 30px;
		}

		p {
			font-size: 18px;
		}
	}

	.proj-blurb {
		p {
			font-size: 20px;
		}
	}

	.giphy {
		width: 45%;
		margin-right: 8%;
		height: 22vh;
	}

	#id3 {
		height: 31vh;
		width: 32%;
	}

	.proj-nav {
		margin-top: 20px;
	}

	.link-icon,
	.proj-link {
		font-size: 30px;
	}

	/*--footer--*/
	.footer-icon {
		font-size: 30px;
		margin: 45px;
	}

	.footer {
		border: 5px solid black;
	}

	/*----contact page----*/
	.contact-title {
		font-size: 55px;
	}

	.contact-link-container {
		span {
			margin-left: 15px;
			font-size: 20px;
		}
	}
}

@media screen and (min-width: 1200px) {
	/*---home page--*/
	.header {
		height: 65vh;
	}

	.home-title {
		font-size: 60px;
	}

	.home-sub {
		margin-top: 20px;
		font-size: 30px;
	}

	/*---about section---*/
	.about-top-section {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 90%;
		margin-bottom: 20px;
	}

	.about-text-container {
		width: 70%;
	}

	.about-img {
		width: 30%;
		height: 30%;
		position: relative;
		left: 20px;
		top: -90px;
	}

	.about-container {
		padding-bottom: 65px;
	}

	.about-title,
	.skills-title {
		font-size: 35px;
	}

	.about-text {
		font-size: 18px;
		margin-bottom: 25px;
	}

	/*--highlights--*/
	.highlight-section-container {
		padding-bottom: 20px;
	}

	.highlight-container {
		width: 40%;
	}

	.highlight-icon {
		font-size: 65px;
	}

	.highlight-par {
		font-size: 18px;
	}

	/*--skills section--*/
	.skills-title {
		margin-top: 50px;
		margin-bottom: 80px;
	}

	/*--bubbles--*/
	.tech-container {
		width: 150px;
		height: 150px;
		margin: 50px;
	}

	.tech-icon {
		font-size: 40px;
	}

	.tech-label {
		font-size: 20px;
	}

	/*--projects page--*/
	.proj-comp-container {
		width: 100%;
		margin-top: 100px;
		@include flex-row;
		flex-wrap: wrap;
	}

	.proj-title {
		font-size: 60px;
		margin-top: 110px;
	}

	.block {
		padding: 5px;
		width: 40%;
		height: 45vh;
		margin: 20px;
	}

	.proj-blurb {
		padding: 40px;
		width: 35%;
		margin: 20px;
		align-items: flex-start;

		p {
			font-size: 13px;
		}
	}

	.proj-nav {
		margin-top: 25px;
	}

	.content {
		justify-content: flex-start;

		h2 {
			font-size: 27px;
		}

		p {
			font-size: 13px;
		}
	}

	#id3 {
		width: 36%;
		height: 26vh;
	}

	.giphy {
		height: 19vh;
	}

	.proj-nav {
		margin-top: 15px;
	}

	.link-icon,
	.proj-link {
		font-size: 25px;
	}

	/*----contact page----*/
	.contact-title {
		font-size: 45px;
	}

	.photo-container {
		width: 60%;
		padding: 15px;

		h1 {
			font-size: 25px;
		}
	}

	.photo-box {
		p {
			font-size: 17px;
		}
	}

	.contact-img {
		width: 80%;
	}

	.main-link-container {
		width: 60%;
	}

	.contact-link-container span {
		margin-left: 15px;
	}
}
